const input = {
    username: "Nom d'utilisateur",
    password: 'Mot de passe',
    email: 'Email',
    phone_number: 'Numéro de téléphone',
    confirm_password: 'Confirmer le mot de passe',
    search_region_code: 'Rechercher le code de région',
    backup_code: 'Code de sauvegarde',
};
export default Object.freeze(input);
